import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "접촉 면회 재개 안내",
  "subtitle": "코로나 면회 방침 변경.",
  "date": "2022-10-04T00:00:00.000Z",
  "author": "관리자"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`요양병원 방역수칙 변경 안내`}</h1>
    <h2>{`추진배경`}</h2>
    <p>{`최근 감염취약시설 확진자 감소 추세, 높은 4차 백신 접종률 등을 고려하여 요양병원 방역수칙을 6차 유행 이전 수준으로 개편 `}<br /></p>
    <h2>{`주요 개편 방안`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`(면회) 비접촉 대면면회 👉 `}<u>{`접촉 대면면회`}</u></strong>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`접촉 면회제한은 폐지`}</strong>{`하되, `}<strong parentName="li">{`사전예약`}</strong>{` 및 `}<strong parentName="li">{`음식물 섭취 금지, 실내 마스크 착용`}</strong>{` 등 `}<strong parentName="li">{`방역수칙은 철저히 준수`}</strong></li>
        </ul>
      </li>
      <li parentName="ol">{`(외출·외박) 필수 외래진료만 허용 👉 `}<u>{`조건부 전면 허용`}</u>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`감염예방 및 차단`}</strong>{`을 위해 `}<strong parentName="li">{`대상자 제한`}</strong>{` 및 `}<strong parentName="li">{`복귀 시 검사 실시`}</strong></li>
        </ul>
      </li>
    </ol>
    <h2>{`<방역조치 개편방안>`}<br /></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`유 형`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`현 행`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`개 편(10.4~)`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`허용조건`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`면회`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`비접촉 대면 면회`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`접촉면회 허용`}</strong><br /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`(면회객)`}</strong>{`자가진단키트(RAT)사전 음성 확인*`}<em parentName="td"><br /></em>{` 접촉면회 금지`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`외출·외박`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`필수 외래진료만 허용`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`조건부 전면 허용`}</strong></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><strong parentName="td">{`(대상)`}</strong>{`  4차 접종자 또는 2차 이상 접종과 확진 이력이 있는 자 `}<br />{`(복귀시 RAT검사 실시)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`적용시기 : '22.10.4`}{`[화]`}{`~, 별도 안내시까지`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`추후 확진자 발생 등 방역상황에 따라 변경 가능`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li"><span style={{
                "color": "red"
              }}>{`접촉 대면 면회 전 방문자 명부작성, 체온측정, 손 소독 등에 협조 바랍니다.`}</span></strong></li>
          <li parentName="ul"><strong parentName="li"><span style={{
                "color": "red"
              }}><u>{`접촉 대면 면회 도중 마스크를 벗거나 음식섭취는 불가합니다`}</u></span></strong></li>
        </ul>
      </li>
    </ul>
    <br />
    <h2>{`접촉 면회 예약시 안내사항`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li"><span style={{
            "color": "red"
          }}>{`"접촉 대면 면회 사전예약 필수 및 면회시간 엄수"`}</span></strong></li>
      <li parentName="ul">{`예약전화 가능시간 : 오전10시~오후5시 `}<a parentName="li" {...{
          "href": "tel:010-2844-9989"
        }}>{`📞010-2844-9989`}</a>{`
(예약없이 방문할 경우 면회 불가)`}</li>
      <li parentName="ul">{`면회인원은 4인 이내로 가능하며, `}<strong parentName="li">{`추가인원은 면회 불가`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      